<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Разница дисциплин</h4>

      <div v-if="disciplinesDifference_form.disciplinesDifference.length != 0">
        <DataTable :value="disciplinesDifference_form.disciplinesDifference" showGridlines stripedRows responsiveLayout="scroll">


          <Column header="Выбор">
            <template #body="{data}">
              <input class="form-check-input big-checkbox" type="checkbox"
                     :value="data.id"
                     v-model="checked_disciplines">
            </template>
          </Column>

          <Column field="discipline_code" header="Код"></Column>
          <Column field="discipline_name" header="Название"></Column>
          <Column field="language" header="Язык"></Column>
          <Column field="study_course" header="Курс"></Column>
          <Column field="semester" header="Семестр"></Column>
          <Column field="credit" header="Кредит"></Column>




        </DataTable>

        <div class="my-5" v-if="checked_disciplines.length">
          <div>
            <b>Выбранные дисциплины:</b>
            <ul class="mb-1 text-danger">
              <li v-for="(discipline, disciplineIndex) in checkedDisciplines" :key="disciplineIndex">
                {{ discipline?.code }} {{ discipline?.name }}
              </li>
            </ul>
          </div>
          <div class="my-3 text-center">
            <Button icon="pi pi-save" :loading="btnSaveDisabled" label="Сохранить" @click="saveDifference"/>
          </div>
        </div>
      </div>

      <div v-else>
        <p>Дисциплины уже добавлены</p>
      </div>










    </div>
  </div>
</template>


<script>

import {mapGetters, mapActions, mapMutations, mapState} from 'vuex'

export default {
  name: "DisciplinesDifference",
  data() {
    return {
      loading: true,
      btnSaveDisabled: false,
      urlStudentId: +this.$route.query.student_id || 0,
      urlOrderFrom: +this.$route.query.order_from || 0,
      urlOrderTo: +this.$route.query.order_to || 0,
      checked_disciplines: [],
      student_ratings: []
    }
  },
  computed: {
    ...mapState('disciplinesDifference', ['disciplinesDifference_form']),

    checkedDisciplines() {
      const disciplines = []
      this.checked_disciplines.forEach(i => {
        disciplines.push({
          name: this.disciplinesDifference_form.disciplinesDifference?.find(s => s.id === i).discipline_name,
          code: this.disciplinesDifference_form.disciplinesDifference?.find(s => s.id === i).discipline_code,
          course_id: this.disciplinesDifference_form.disciplinesDifference?.find(s => s.id === i).course_id,
          credit: this.disciplinesDifference_form.disciplinesDifference?.find(s => s.id === i).credit,
          credit_old: this.disciplinesDifference_form.disciplinesDifference?.find(s => s.id === i).credit,
          semester: this.disciplinesDifference_form.disciplinesDifference?.find(s => s.id === i).semester,
          sp_academic_year_id: this.disciplinesDifference_form.disciplinesDifference?.find(s => s.id === i).sp_academic_year_id,
          student_study_course: this.disciplinesDifference_form.disciplinesDifference?.find(s => s.id === i).study_course,

        })
      })
      console.log(disciplines,'disciplines')
      return disciplines
    },
  },
  methods: {
    ...mapActions('disciplinesDifference', ['GET_DISCIPLINES_DIFFERENCE', 'POST_DIFFERENCE_STUDENT_RATINGS']),
    async saveDifference() {
      this.btnSaveDisabled = true


      this.student_ratings = this.checkedDisciplines.map(i => ({
        student_id: this.urlStudentId,
        course_id: i.course_id,
        credit: i.credit,
        credit_old: i.credit,
        semester: i.semester,
        sp_academic_year_id: i.sp_academic_year_id,
        student_study_course: i.student_study_course,
      }))

      console.log(this.student_ratings, 'save')


      const res = await this.POST_DIFFERENCE_STUDENT_RATINGS(this.student_ratings)
      if (res) {
        this.$message({text: 'Сохранено'})
        this.GET_DISCIPLINES_DIFFERENCE({student_id: this.urlStudentId, order_from: this.urlOrderFrom, order_to: this.urlOrderTo})
      }

      this.btnSaveDisabled = false
    }

  },
  async mounted() {
    await this.GET_DISCIPLINES_DIFFERENCE({student_id: this.urlStudentId, order_from: this.urlOrderFrom, order_to: this.urlOrderTo})


    this.loading = false
  }
}
</script>

<style scoped>
.big-checkbox {
  width: 20px;
  height: 20px;
}
</style>